import React from "react"
import { Link } from "gatsby"

{
  /* 
    Available Colors:
    - Primary
    - Secondary
    - Accent
    - Ghost
*/
}

export default function Button(props) {
  return (
    <>
      {!props.link ? (
        <button
          className={`btn ${props.type ? `btn-${props.type}` : ""}`}
          role="button"
        >
          {props.label}
        </button>
      ) : (
        <Link
          className={`btn ${props.type ? `btn-${props.type}` : ""}`}
          role="button"
          to="/"
        >
          {props.label}
        </Link>
      )}
    </>
  )
}

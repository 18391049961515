import React, { useState } from "react"
import Button from '../button'

export default function GetStarted(props) {

    let data = props.data
    const [count, setCount] = useState(1)

  return (
    <div className="step-wrapper">
      <ul className="steps steps-vertical">
        <li className={`step one ${count >= 1 ? "step-primary" : ''}`}>One</li>
        <li className={`step two ${count >= 2 ? "step-primary" : ''}`}>Two</li>
        <li className={`step three ${count >= 3 ? "step-primary" : ''}`}>Three</li>
        <li className={`step four ${count >= 4 ? "step-primary" : ''}`}>Four</li>
      </ul>
      <div className="step-content p-10 shadow-lg bg-base-200 rounded-box">
        <h2 className="text-primary text-2xl mb-2">{data[`${count}`]['title']}</h2>
        <p>{data[`${count}`]['description']}</p>
        {data[`${count}`]['link'] ? <p><a href={data[`${count}`]['link']} target="_blank" className="text-primary mt-5 inline-flex">Visit Discord</a></p> : null}
            <div className="button-wrapper mt-5">
                {count > 1 ?
                    <button className="btn btn-primary mr-3" onClick={() => setCount(count - 1)}>Last Step</button> 
                : null }
                {count < 4 ?
                    <button className="btn btn-primary" onClick={() => setCount(count + 1)}>Next Step</button> 
                :
                    <Button type="primary" label="Go To App!" link="/" /> 
                }
            </div>
      </div>
    </div>
  )
}
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from '../components/hero'
import Steps from '../components/stepper'

import useAxios from 'axios-hooks'
import ls from 'local-storage'

export default function GetStarted() {

  const [{ data, loading }, refetch] = useAxios(
    'https://deepprofits.org/star_atlas_fleet/fleet_generator.php?f=getStarted'
  )

  const theme = ls.get('theme')

  return (
    <Layout>
    <SEO title="Get Started" />
      <div data-theme={theme}>
        <Hero />
        { data ? <Steps data={data} /> : null }
      </div>
    </Layout>
  )
}
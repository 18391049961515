import React from 'react'
import Banner from '../../images/getstarted_banner.jpeg'
import Button from '../button'

export default function Hero() {

  const bgStyles = {
    backgroundImage: `url(${Banner})`,
    backgroundPosition: `center`,
    backgroundSize: `cover`
  }

  return (
    <div class="hero" style={bgStyles}>
      <div class="hero-overlay bg-opacity-60"></div> 
      <div class="text-center hero-content text-neutral-content">
        <div class="max-w-md">
          <h1 class="mb-5 text-5xl font-bold text-primary">Get Fleet Pass</h1> 
          <p class="mb-5">Welcome to Fleet Pass. Ready to get statistics on your fleet? Read more below or open the app!</p>
          <Button type="primary" link="/" label="Open App" />
        </div>
      </div>
    </div>
  )
}
